<template>
  <div class="personalCenterContainer"  v-loading="loading">
    <div class="personalCenterTitle">
      <span>企业认证</span>
      <a
        v-if="enterpriseLink && form.status == 6"
        :href="enterpriseLink"
        target="_blank"
        >&nbsp;&nbsp;第三方认证地址 :
        {{ enterpriseLink ? enterpriseLink : "--" }}</a
      >
      <el-button
        v-if="enterpriseLink && form.status == 6"
        size="mini"
        style="font-size: 16px; padding: 4px 8px"
        v-clipboard:copy="enterpriseLink"
        v-clipboard:success="onCopy"
        v-clipboard:error="onError"
        >复制</el-button
      >
    </div>

    <div class="hasIdentification">
      <el-alert type="success" :closable="false" v-if="form.status == 2">
        <template slot="title">
          <span>{{ form.status | enterStatusFormate }}</span>
          <!-- <span v-if="contractOfficeStatus"
            >&nbsp;&nbsp;&nbsp;第三方认证状态 ：{{
              contractOfficeStatus | contractOfficeStatusFormate
            }}</span
          > -->
        </template>
      </el-alert>
    </div>
    <div class="hasIdentification">
      <el-alert
        class="auditResult"
        v-if="form.status == 3 || form.status == 5"
        title="审核结果：不通过"
        :description="
          '会员部审核意见：' +
          audit.memberNote +
          '；风控部审核意见：' +
          audit.riskNote
        "
        type="error"
      >
      </el-alert>
    </div>
    <div v-if="form.status == 1 || form.status == 4" class="hasIdentification">
      <el-alert
        title="您的申请处于待审核状态"
        type="warning"
        :closable="false"
      ></el-alert>
    </div>
    <div v-if="form.status == 6">
      <el-alert
        title="第三方认证中"
        type="warning"
        :closable="false"
      ></el-alert>
    </div>
    <div v-if="form.status == 7">
      <el-alert
        title="第三方认证失败"
        type="warning"
        :closable="false"
      ></el-alert>
    </div>
    <!-- <div v-if="form.status == 2 " class="hasIdentification">
      <el-alert title="您的认证已通过" type="success" :closable="false"></el-alert>
    </div> -->

    <div class="goIdentification">
      <el-form ref="form" :model="form" :rules="rules" label-width="140px">
        <el-row>
          <el-col :span="24">
            <el-row :gutter="20">
              <el-col :span="11">
                <el-form-item label="企业名称" prop="enterpriseName">
                  <el-input
                    v-model="form.enterpriseName"
                    :disabled="isAlreadyDisabled"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="11" :offset="2">
                <el-form-item label="统一社会信用代码" prop="registerNo">
                  <el-input
                    v-model="form.registerNo"
                    :disabled="isAlreadyDisabled"
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :span="11">
                <el-form-item label="法定代表人" prop="corporateName">
                  <el-input
                    v-model="form.corporateName"
                    :disabled="isAlreadyDisabled"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="11" :offset="2">
                <el-form-item label="法定代表人手机号" prop="enterprisePhone">
                  <el-input
                    v-model="form.enterprisePhone"
                    :disabled="isAlreadyDisabled"
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :span="11">
                <el-form-item label="业务联系人" prop="businessContact">
                  <el-input
                    v-model="form.businessContact"
                    :disabled="isAlreadyDisabled"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="11" :offset="2">
                <el-form-item label="业务员联系方式" prop="businessPhone">
                  <el-input
                    v-model="form.businessPhone"
                    :disabled="isAlreadyDisabled"
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :span="24">
                <el-form-item label="企业地址" prop="enterpriseAddress">
                  <el-input
                    v-model="form.enterpriseAddress"
                    :disabled="isAlreadyDisabled"
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :span="8">
                <el-form-item label="营业执照" prop="businessPhoto">
                  <el-upload
                    class="avatar-uploader"
                    :action="actionUrl"
                    :show-file-list="false"
                    :on-success="handleAvatarSuccess"
                    :before-upload="beforeAvatarUploadFile"
                    :disabled="isAlreadyDisabled"
                    :data="postDataFile"
                  >
                    <el-image
                      v-if="form.businessPhoto"
                      :src="form.businessPhoto"
                      :preview-src-list="
                        isAlreadyDisabled ? [].concat(form.businessPhoto) : []
                      "
                      class="avatar"
                    />
                    <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                  </el-upload>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item
                  label="法人身份证正面"
                  prop="corporatePositiveCard"
                >
                  <el-upload
                    class="avatar-uploader"
                    :action="actionUrl"
                    :show-file-list="false"
                    :on-success="handlePositiveCardSuccess"
                    :before-upload="beforePositiveCardUpload"
                    :disabled="isAlreadyDisabled"
                    :data="postData"
                  >
                    <el-image
                      v-if="form.corporatePositiveCard"
                      :src="form.corporatePositiveCard"
                      :preview-src-list="
                        isAlreadyDisabled
                          ? [].concat(form.corporatePositiveCard)
                          : []
                      "
                      class="avatar"
                    />
                    <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                  </el-upload>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item
                  label="法人身份证反面"
                  prop="corporateReverseCard"
                >
                  <el-upload
                    class="avatar-uploader"
                    :action="actionUrl"
                    :show-file-list="false"
                    :on-success="handleReverseCardSuccess"
                    :before-upload="beforeReverseCardUpload"
                    :disabled="isAlreadyDisabled"
                    :data="postData"
                  >
                    <el-image
                      v-if="form.corporateReverseCard"
                      :src="form.corporateReverseCard"
                      :preview-src-list="
                        isAlreadyDisabled
                          ? [].concat(form.corporateReverseCard)
                          : []
                      "
                      class="avatar"
                    />
                    <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                  </el-upload>
                </el-form-item>
              </el-col>
            </el-row>

            <div v-if="!isAlreadyDisabled">
              <el-form-item label prop="enterpriseAddress">
                <p class="tip">
                  温馨提示：上传图片为jpeg，png或jpg格式，且上传图片大小不能超过2MB！
                </p>
              </el-form-item>
            </div>
          </el-col>
        </el-row>
        <!-- <el-form-item label="企业类型" prop="firmType" :disabled="isAlreadyDisabled">
          <el-radio-group v-model="form.firmType">
            <el-radio :label="2">买家</el-radio>
            <el-radio :label="3">卖家</el-radio>
          </el-radio-group>
        </el-form-item>-->
        <div class="submitIdentification" v-if="!isAlreadyDisabled">
          <el-button type="danger" @click="submitIdentification()"
            >提交</el-button
          >
        </div>
      </el-form>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      loading: false,
      localFile: null,
      isShowIdentificationForm: true,
      form: {
        corporateName: null,
        businessPhoto: null,
        businessPhotoFile: null,
        corporatePositiveCard: null,
        corporateReverseCard: null,
        enterpriseAddress: null,
        enterpriseName: null,
        enterprisePhone: null,
        registerNo: null,
        firmType: 3,
        businessContact: null,
        businessPhone: null,
      },
      businessPhotoFile: null,
      rules: {
        corporateName: [
          { required: true, message: "请输入法人名称", trigger: "change" },
        ],
        businessPhoto: [
          { required: true, message: "再次提交时请重新上传营业执照", trigger: "change" },
        ],
        corporatePositiveCard: [
          {
            required: true,
            message: "请上传法人身份证正面",
            trigger: "change",
          },
        ],
        corporateReverseCard: [
          {
            required: true,
            message: "请上传法人身份证反面",
            trigger: "change",
          },
        ],
        enterpriseName: [
          { required: true, message: "请输入企业名称", trigger: "change" },
        ],
        registerNo: [
          {
            required: true,
            message: "请输入统一社会信用代码",
            trigger: "change",
          },
        ],
        businessContact: [
          { required: true, message: "请输入业务联系人", trigger: "change" },
        ],
        businessPhone: [
          {
            required: true,
            message: "请输入业务员联系方式",
            trigger: "change",
          },
        ],
        enterpriseAddress: [
          { required: true, message: "请输入企业地址", trigger: "change" },
        ],
        enterprisePhone: [
          { required: true, message: "请输入企业手机号", trigger: "change" },
          {
            pattern: /^1[3456789]\d{9}$/,
            message: "手机号格式不正确",
            trigger: "blur",
          },
        ],
      },
      isAlreadyDisabled: false,
      actionUrl: window.g.APP_USER_PATH_UPLOAD,
      postData: {
        uid: this.$store.state.common.sessionInfo.firmId,
        deleteStatus: 0,
        width: null,
        height: null,
        size: null,
      },
      postDataFile:{
        businessPhotoFile: null
      },
      dialogVisible1: false,
      checkedAgree: false,
      enterpriseLink: null,
      contractOfficeStatus: null,
      audit: {
        memberNote: null,
        riskNote: null,
      },
    };
  },

  mounted() {
    this.getIdentificationStatus();
    this.enterpriseLink = localStorage.getItem('enterpriseLink')
  },
  methods: {
    getIdentificationStatus() {
      http
        .postFront(protocolFwd.param_enterpriseCertification)
        .then((response) => {
          const { code, message, value } = response.data;
          if (code == 0) {
            this.$refs["form"].clearValidate();
            if (Object.keys(value).length !== 0) {
              // this.enterPriseFirmId = value.firm_id
              if(value.status == 5 || value.status == 3 || value.status == 7){
                this.form = {
                  corporateName: value.corporate_name,
                  businessPhoto: null,
                  corporatePositiveCard: value.corporate_positive_card,
                  corporateReverseCard: value.corporate_reverse_card,
                  enterpriseAddress: value.enterprise_address,
                  enterpriseName: value.enterprise_name,
                  registerNo: value.register_no,
                  enterprisePhone: value.enterprise_phone,
                  firmType: value.firm_type,
                  status: value.status,
                  businessPhone: value.business_phone,
                  businessContact: value.business_contact,
               };
              }else{
                this.form = {
                  corporateName: value.corporate_name,
                  businessPhoto: value.business_photo,
                  corporatePositiveCard: value.corporate_positive_card,
                  corporateReverseCard: value.corporate_reverse_card,
                  enterpriseAddress: value.enterprise_address,
                  enterpriseName: value.enterprise_name,
                  registerNo: value.register_no,
                  enterprisePhone: value.enterprise_phone,
                  firmType: value.firm_type,
                  status: value.status,
                  businessPhone: value.business_phone,
                  businessContact: value.business_contact,
                };
              }
              // if (value.status == 2) {
              //   this.getEnterpriseLink(value.firm_id);
              // }
              if (value.status == 1 || value.status == 2 || value.status == 4) {
                this.isAlreadyDisabled = true;
              } else {
                this.audit.memberNote = value.member_note ? value.member_note : "--";
                this.audit.riskNote = value.risk_note ? value.risk_note : "--";
              }
            }
          } else {
            this.$EL_MESSAGE(message);
          }
        });
    },
    onCopy() {
      this.$EL_MESSAGE("复制成功");
    },
    onError() {
      this.$EL_MESSAGE("复制失败");
    },
    getCompanyAuthStatus(currentName) {
      protocolFwd.param_companyAuthStatus.param.enterpriseName = currentName;
      http.postFront(protocolFwd.param_companyAuthStatus).then((response) => {
        const { code, message, value } = response.data;
        if (code == 0) {
          this.contractOfficeStatus = value;
        } else {
          this.$EL_MESSAGE(message);
        }
      });
    },
    //企业认证链接
    getEnterpriseLink(id) {
      let that = this;
      protocolFwd.param_companyAuth.param.firmId = id;
      http.postFront(protocolFwd.param_companyAuth).then((response) => {
        const { code, message, value } = response.data;
        if (code == 0) {
          // value=2时为已认证
          if (value == "2") {
            this.contractOfficeStatus = "2";
          } else {
            that.enterpriseLink = value;
            that.getCompanyAuthStatus(that.form.enterpriseName);
          }
        } else {
          that.getCompanyAuthStatus(that.form.enterpriseName);
          this.$EL_MESSAGE(message);
        }
      });
    },
    showIdentificationForm() {
      this.isShowIdentificationForm = true;
    },
    handleAvatarSuccess(res, file) {
      console.log(res,'xxx')
      this.form.businessPhoto = res.value;
    },
    // 企业认证图片获取
    beforeAvatarUploadFile(file) {
      const testmsg = /^image\/(jpeg|png|jpg)$/.test(file.type);
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!testmsg) {
        this.$EL_MESSAGE.error("上传图片格式不对!");
        return;
      }
      if (!isLt2M) {
        this.$EL_MESSAGE.error("上传图片大小不能超过 2MB!");
      }
      // 获取图片原始数据
      this.localFile = file;
      return testmsg && isLt2M;
    },
    beforeAvatarUpload(file) {
      const testmsg = /^image\/(jpeg|png|jpg)$/.test(file.type);
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!testmsg) {
        this.$EL_MESSAGE.error("上传图片格式不对!");
        return;
      }
      if (!isLt2M) {
        this.$EL_MESSAGE.error("上传图片大小不能超过 2MB!");
      }
      return testmsg && isLt2M;
    },
    handlePositiveCardSuccess(res, file) {
      this.form.corporatePositiveCard = res.value;
    },
    beforePositiveCardUpload(file) {
      const testmsg = /^image\/(jpeg|png|jpg)$/.test(file.type);
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!testmsg) {
        this.$EL_MESSAGE.error("上传图片格式不对!");
        return;
      }
      if (!isLt2M) {
        this.$EL_MESSAGE.error("上传图片大小不能超过 2MB!");
      }
      return testmsg && isLt2M;
    },
    handleReverseCardSuccess(res, file) {
      this.form.corporateReverseCard = res.value;
    },
    beforeReverseCardUpload(file) {
      const testmsg = /^image\/(jpeg|png|jpg)$/.test(file.type);
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!testmsg) {
        this.$EL_MESSAGE.error("上传图片格式不对!");
        return;
      }
      if (!isLt2M) {
        this.$EL_MESSAGE.error("上传图片大小不能超过 2MB!");
      }
      return testmsg && isLt2M;
    },
    submitIdentification() {
      console.log()
      if(this.localFile == null || this.localFile==undefined){
        this.form.businessPhoto = null
        this.$refs["form"].validate((valid) => {})
        return
      }
      this.$refs["form"].validate((valid) => {
        if (valid) {
          this.loading = true
          let _this = this
          // FormData 对象
          var form = new FormData();
          form.append("corporateName",_this.form.corporateName);
          form.append("businessPhoto", _this.form.businessPhoto);
          form.append("corporatePositiveCard", _this.form.corporatePositiveCard);
          form.append("corporateReverseCard", _this.form.corporateReverseCard);
          form.append("enterpriseAddress", _this.form.enterpriseAddress);
          form.append("enterpriseName", _this.form.enterpriseName);
          form.append("enterprisePhone", _this.form.enterprisePhone);
          form.append("registerNo", _this.form.registerNo);
          form.append("firmType", _this.form.firmType);
          form.append("businessContact", _this.form.businessContact);
          form.append("businessPhone", _this.form.businessPhone);
          form.append("businessPhotoFile", _this.localFile);

          protocolFwd.param_addCertification.param = form;
          http
            .postFront(protocolFwd.param_addCertification)
            .then((response) => {
              const { code, message, value } = response.data;
              if (code == 0) {
                this.loading = false
                let that = this;
                this.$EL_MESSAGE(message);
                localStorage.setItem('enterpriseLink',value)
                if(value != 'AUTH_SUCCESS'){
                  window.location.href = value
                }
                setTimeout(function () {
                  that.getIdentificationStatus();
                }, 1000);
              } else {
                this.$EL_MESSAGE(message);
              }
            });
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.laodingBox{
  position: fixed;
  top: 100px;
  margin: 0 auto;
}
.personalCenterContainer {
  line-height: 32px;
}
.personalCenterContainer .personalCenterTitle {
  font-size: 16px;
  border-bottom: 1px solid #eeeeee;
}
.personalCenterContainer .personalCenterTitle span {
  display: inline-block;
  padding: 0 15px;
  border-bottom: 2px solid $commonThemeColor;
}
.noIdentification {
  margin: 100px 0;
  text-align: center;
}
.noIdentificationText {
  font-size: 18px;
  color: #9999a2;
}
.hasIdentification {
  margin: 20px 20px 0 20px;
}
.goIdentification {
  padding: 30px 20px 0 20px;
}
.tip {
  color: red;
  line-height: 30px;
  font-weight: 700;
}
/* 头像 开始 */
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 200px;
  height: 120px;
  line-height: 120px;
  text-align: center;
}
.avatar {
  width: 200px;
  height: 120px;
  display: block;
}
.submitIdentification {
  text-align: center;
}
/* 头像 结束 */
</style>
